import React, { useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Backdrop,
  CardHeader,
  Divider,
  Fade,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { history } from "app/redux/store";
import LinkIcon from "@mui/icons-material/Link";
import SyncIcon from "@mui/icons-material/Sync";
import InputMask from "react-input-mask";
import api from "../../../../services/config";
import { useSelector } from "react-redux";
import moment from "moment";
import PasswordField from "app/components/PasswordField";
import { FileUpload, Visibility } from "@mui/icons-material";

const FormTecnico = ({ user, setLoading }) => {
  const { auth } = useSelector((state) => state);

  const [status, setStatus] = useState(null);

  const [changeFiles, setChangeFiles] = useState({
    cnh_frente: false,
    cnh_verso: false,
    document: false,
    antecedentes_criminais: false,
    curriculo: false,
  });

  function changeField(field) {
    const old = changeFiles;
    old[field] = !old[field];

    setChangeFiles(old);
  }

  const validationSchema = Yup.object({
    data: Yup.object().shape({
      name: Yup.string().required("Campo obrigatório"),
      email: Yup.string().email().required("Campo obrigatório"),
      password: Yup.string().optional(),
      cel: Yup.string().required("Campo obrigatório"),
      cpf: Yup.string().required("Campo obrigatório"),
      empresa: Yup.string().optional(),
    }),
    address: Yup.object().shape({
      cep: Yup.string().required("Campo obrigatório"),
      logradouro: Yup.string().required("Campo obrigatório"),
      cidade: Yup.string().required("Campo obrigatório"),
      estado: Yup.string().required("Campo obrigatório"),
      numero: Yup.string().required("Campo obrigatório"),
      complemento: Yup.mixed().optional(),
    }),
    documents: Yup.object().shape({
      tipo_de_deslocamento: Yup.string().required("Campo obrigatório"),
      cnh_frente: Yup.string().optional(),
      cnh_verso: Yup.string().optional(),
      vencimento_cnh: Yup.string().nullable().optional(),
      documento: Yup.string().optional(),
      vencimento_documento: Yup.string().nullable().optional(),
      antecedentes_criminais: Yup.string().optional(),
      curriculo: Yup.string().optional(),
    }),
    payment: Yup.object().shape({
      valor_km: Yup.number().required("Campo obrigatório"),
      valor_primeiro_atendimento: Yup.number().required("Campo obrigatório"),
      valor_retorno: Yup.number().required("Campo obrigatório"),
    }),
  });

  const [filesToUpload, setFilesToUploads] = useState({});
  const [photo, setPhoto] = useState(null);
  const [alertParams, setAlertParams] = useState({
    type: "",
    message: "",
    show: false,
  });
  const [description, setDescription] = useState("");
  const [showObservation, setShowObservation] = useState(false);

  const formik = useFormik({
    initialValues: {
      data: {
        name: "",
        email: "",
        password: "",
        cel: "",
        cpf: "",
        empresa: "",
      },
      address: {
        cep: "",
        logradouro: "",
        cidade: "",
        estado: "",
        numero: "",
        complemento: "",
      },
      documents: {
        tipo_de_deslocamento: "",
        cnh_frente: "",
        cnh_verso: "",
        vencimento_cnh: "",
        documento: "",
        vencimento_documento: "",
        antecedentes_criminais: "",
        curriculo: "",
      },
      payment: {
        valor_km: "",
        valor_primeiro_atendimento: "",
        valor_retorno: "",
      },
    },
    validationSchema: validationSchema,
    onSubmit: submitForm,
  });

  async function submitForm(data) {
    if (!user && !data.data.password) {
      formik.setFieldError("data.password", "Campo obrigatório");
      return;
    }

    const dataTecnico = {
      ...data.data,
      ...data.address,
      ...data.documents,
      ...data.payment,
      ...filesToUpload,
    };

    if (user && dataTecnico.password == "") delete dataTecnico.password;
    if (user) delete dataTecnico.email;

    if (photo) {
      dataTecnico["foto"] = photo;
    }
    if (status !== null) dataTecnico["status"] = status;

    const formData = new FormData();

    Object.keys(dataTecnico).map((key) => {
      if (dataTecnico[key]) {
        formData.append(key, dataTecnico[key]);
      }
    });

    if (user) formData.append("_method", "PUT");

    setLoading(true);
    try {
      const { data } = await api.post(
        `api/v2/dashboard/tecnicos/${user ? user.id : ""}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      console.log("DATA >> > ", data);

      setAlertParams({
        type: "success",
        message: `Técnico ${user ? "atualizado" : "criado"} com sucesso`,
        show: true,
      });

      window.location.reload();
    } catch (error) {
      console.log("ERROR CREATE TECNICO > > ", error);
      setAlertParams({
        type: "error",
        message: `Ocorreu um erro ao ${
          user ? "atualizar" : "criar"
        } o técnico.`,
        show: true,
      });
    }
    // setStatus("Pendente");
    window.scrollTo(0, 0);
    setLoading(false);
  }

  useEffect(() => {
    if (user) {
      formik.setFieldValue("data.name", user.name);
      formik.setFieldValue("data.email", user.email);
      formik.setFieldValue("data.cel", user.cel);
      formik.setFieldValue("data.cpf", user.cpf);
      formik.setFieldValue("data.empresa", user.empresa);

      formik.setFieldValue("address.cep", user.tecnico.cep);
      formik.setFieldValue("address.logradouro", user.tecnico.logradouro);
      formik.setFieldValue("address.cidade", user.tecnico.cidade);
      formik.setFieldValue("address.estado", user.tecnico.estado);
      formik.setFieldValue("address.numero", user.tecnico.numero);
      formik.setFieldValue("address.complemento", user.tecnico.complemento);

      //pagamento
      formik.setFieldValue("payment.valor_km", user.tecnico.valor_km);
      formik.setFieldValue(
        "payment.valor_primeiro_atendimento",
        user.tecnico.valor_primeiro_atendimento
      );
      formik.setFieldValue("payment.valor_retorno", user.tecnico.valor_retorno);

      formik.setFieldValue(
        "documents.tipo_de_deslocamento",
        user.tecnico.tipo_de_deslocamento
      );
      formik.setFieldValue(
        "documents.vencimento_cnh",
        user.tecnico.vencimento_cnh
      );
      formik.setFieldValue(
        "documents.vencimento_documento",
        user.tecnico.vencimento_documento
      );
    }
  }, [user]);

  async function recusaTecnico() {
    setLoading(true);
    try {
      const { data } = await api.post(
        `api/v2/dashboard/tecnicos/refuse/${user.id}`,
        { description },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      console.log(data);

      setAlertParams({
        type: "success",
        message: `Técnico repovado!`,
        show: true,
      });
    } catch (error) {
      console.log("ERROR REFUSE > > > ", error);
      setAlertParams({
        type: "error",
        message: `Ocorreu um erro ao recusar o técnico.`,
        show: true,
      });
    }
    setLoading(false);
    setShowObservation(false);
  }

  async function desativaTecnico() {
    setLoading(true);
    try {
      const { data } = await api.delete(
        `api/v2/dashboard/tecnicos/del/${user.id}`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      console.log(data);

      setAlertParams({
        type: "success",
        message: `Técnico desativado com sucesso`,
        show: true,
      });

      window.location.reload();
    } catch (error) {
      console.log("ERROR REFUSE > > > ", error);
      setAlertParams({
        type: "error",
        message: `Ocorreu um erro ao desativar técnico.`,
        show: true,
      });
    }

    window.scrollTo(0, 0);
    setLoading(false);
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      {alertParams.show && (
        <Alert
          sx={{ m: 2 }}
          variant="filled"
          severity={alertParams.type}
          onClose={() => {
            setAlertParams({
              ...alertParams,
              show: false,
            });
          }}
        >
          {alertParams.message}
        </Alert>
      )}

      <Div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={showObservation}
          onClose={() => setShowObservation(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showObservation}>
            <Div
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
                bgcolor: "background.paper",
                borderRadius: 5,
                boxShadow: 24,
                p: 4,
                maxHeight: "95%",
              }}
            >
              <TextField
                label="Observação"
                placeholder="Justifique o motivo do técnico estar sendo reprovado..."
                focused
                id="observação"
                multiline
                rows={5}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                sx={{
                  width: "100%",
                }}
              />

              <Button
                onClick={recusaTecnico}
                color="success"
                variant="contained"
                style={{
                  width: "100%",
                  marginTop: 10,
                }}
              >
                Enviar
              </Button>
            </Div>
          </Fade>
        </Modal>
      </Div>

      <CardHeader title="Dados do Técnico" sx={{ mt: -1, ml: -1 }} />
      <Divider sx={{ mb: 2, mt: -1 }} />

      <Stack direction="row" flex={1}>
        <Div sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Stack direction="row">
            <FormControl>
              <TextField
                fullWidth
                id="data.name"
                label="Nome"
                value={formik.values.data.name}
                onChange={formik.handleChange}
                error={
                  formik.touched.data &&
                  Boolean(formik.errors.data && formik.errors.data.name)
                }
                helperText={
                  formik.touched.data &&
                  formik.errors.data &&
                  formik.errors.data.name
                }
              />
            </FormControl>
            <FormControl>
              <TextField
                fullWidth
                id="data.email"
                type="email"
                label="E-mail"
                disabled={user ? true : false}
                value={formik.values.data.email}
                autoComplete="new-password"
                onChange={formik.handleChange}
                error={
                  formik.touched.data &&
                  Boolean(formik.errors.data && formik.errors.data.email)
                }
                helperText={
                  formik.touched.data &&
                  formik.errors.data &&
                  formik.errors.data.email
                }
              />
            </FormControl>
          </Stack>
          <Stack direction="row">
            <PasswordField formikRef={formik} />
            <FormControl>
              <InputMask
                mask="(99) 99999-9999"
                disabled={false}
                value={formik.values.data.cel}
                onChange={formik.handleChange}
                maskChar=" "
              >
                {() => (
                  <TextField
                    fullWidth
                    id="data.cel"
                    type="tel"
                    label="Telefone"
                    error={
                      formik.touched.data &&
                      Boolean(formik.errors.data && formik.errors.data.cel)
                    }
                    helperText={
                      formik.touched.data &&
                      formik.errors.data &&
                      formik.errors.data.cel
                    }
                  />
                )}
              </InputMask>
            </FormControl>
          </Stack>
          <Stack direction="row">
            <FormControl>
              <InputMask
                mask="999.999.999-99"
                disabled={false}
                value={formik.values.data.cpf}
                onChange={formik.handleChange}
                maskChar=" "
              >
                {() => (
                  <TextField
                    fullWidth
                    id="data.cpf"
                    label="CPF"
                    error={
                      formik.touched.data &&
                      Boolean(formik.errors.data && formik.errors.data.cpf)
                    }
                    helperText={
                      formik.touched.data &&
                      formik.errors.data &&
                      formik.errors.data.cpf
                    }
                  />
                )}
              </InputMask>
            </FormControl>
            <FormControl>
              <TextField
                fullWidth
                id="data.empresa"
                label="Empresa"
                value={formik.values.data.empresa}
                onChange={formik.handleChange}
                error={
                  formik.touched.data &&
                  Boolean(formik.errors.data && formik.errors.data.empresa)
                }
                helperText={
                  formik.touched.data &&
                  formik.errors.data &&
                  formik.errors.data.empresa
                }
              />
            </FormControl>
          </Stack>
        </Div>

        <Div
          onClick={() => {
            const input = document.getElementById("photo-upload");
            input.click();
          }}
        >
          <Avatar
            sx={{
              width: 250,
              height: 250,
              ml: 5,
              bgcolor: "#cecece",
              cursor: "pointer",
            }}
            src={
              photo
                ? URL.createObjectURL(photo)
                : `https://storage.googleapis.com/uteky/images/tecnicos/${
                    user ? user.id : ""
                  }_100x100.jpg?random=${Math.floor(Math.random() * 10000)}`
            }
          />

          {user && (
            <Typography
              sx={{
                textAlign: "center",
                mt: 2,
                transform: "translateX(10%)",
                fontSize: "12px",
                color: "#404040",
              }}
            >
              Membro desde: {moment(user.created_at).format("DD/MM/YYYY")}
            </Typography>
          )}
        </Div>

        <input
          type="file"
          id="photo-upload"
          accept="image/*"
          onChange={(e) => {
            setPhoto(e.target.files[0]);
          }}
          style={{
            display: "none",
          }}
        />
      </Stack>

      <CardHeader title="Endereço" sx={{ mt: -1, ml: -1 }} />
      <Divider sx={{ mb: 2, mt: -1 }} />

      <FormControl>
        <InputMask
          mask="99999-999"
          disabled={false}
          value={formik.values.address.cep}
          onChange={formik.handleChange}
          maskChar=" "
        >
          {() => (
            <TextField
              fullWidth
              id="address.cep"
              label="CEP"
              error={
                formik.touched.address &&
                Boolean(formik.errors.address && formik.errors.address.cep)
              }
              helperText={
                formik.touched.address &&
                formik.errors.address &&
                formik.errors.address.cep
              }
            />
          )}
        </InputMask>
      </FormControl>
      <FormControl>
        <TextField
          fullWidth
          id="address.logradouro"
          label="Logradouro"
          value={formik.values.address.logradouro}
          onChange={formik.handleChange}
          error={
            formik.touched.address &&
            Boolean(formik.errors.address && formik.errors.address.logradouro)
          }
          helperText={
            formik.touched.address &&
            formik.errors.address &&
            formik.errors.address.logradouro
          }
        />
      </FormControl>
      <FormControl>
        <TextField
          fullWidth
          id="address.cidade"
          label="Cidade"
          value={formik.values.address.cidade}
          onChange={formik.handleChange}
          error={
            formik.touched.address &&
            Boolean(formik.errors.address && formik.errors.address.cidade)
          }
          helperText={
            formik.touched.address &&
            formik.errors.address &&
            formik.errors.address.cidade
          }
        />
      </FormControl>
      <FormControl>
        <TextField
          fullWidth
          id="address.estado"
          label="UF"
          value={formik.values.address.estado}
          onChange={formik.handleChange}
          error={
            formik.touched.address &&
            Boolean(formik.errors.address && formik.errors.address.estado)
          }
          helperText={
            formik.touched.address &&
            formik.errors.address &&
            formik.errors.address.estado
          }
        />
      </FormControl>
      <FormControl>
        <TextField
          fullWidth
          id="address.numero"
          label="Número"
          value={formik.values.address.numero}
          onChange={formik.handleChange}
          error={
            formik.touched.address &&
            Boolean(formik.errors.address && formik.errors.address.numero)
          }
          helperText={
            formik.touched.address &&
            formik.errors.address &&
            formik.errors.address.numero
          }
        />
      </FormControl>
      <FormControl>
        <TextField
          fullWidth
          id="address.complemento"
          label="Complemento"
          value={formik.values.address.complemento}
          onChange={formik.handleChange}
          error={
            formik.touched.address &&
            Boolean(formik.errors.address && formik.errors.address.complemento)
          }
          helperText={
            formik.touched.address &&
            formik.errors.address &&
            formik.errors.address.complemento
          }
        />
      </FormControl>
      <CardHeader title="Informações de pagamento" sx={{ mt: -1, ml: -1 }} />
      <Divider sx={{ mb: 2, mt: -1 }} />

      <FormControl>
        <TextField
          fullWidth
          id="payment.valor_km"
          label="Valor do KM"
          value={formik.values.payment.valor_km}
          onChange={formik.handleChange}
          error={
            formik.touched.payment &&
            Boolean(formik.errors.payment && formik.errors.payment.valor_km)
          }
          type="number"
          helperText={
            formik.touched.payment &&
            formik.errors.payment &&
            formik.errors.payment.valor_km
          }
        />
      </FormControl>

      <FormControl>
        <TextField
          fullWidth
          id="payment.valor_primeiro_atendimento"
          label="Valor para ticket Pendente"
          value={formik.values.payment.valor_primeiro_atendimento}
          onChange={formik.handleChange}
          error={
            formik.touched.payment &&
            Boolean(
              formik.errors.payment &&
                formik.errors.payment.valor_primeiro_atendimento
            )
          }
          type="number"
          helperText={
            formik.touched.payment &&
            formik.errors.payment &&
            formik.errors.payment.valor_primeiro_atendimento
          }
        />
      </FormControl>
      <FormControl>
        <TextField
          fullWidth
          id="payment.valor_retorno"
          label="Valor para ticket Resolvido direto"
          value={formik.values.payment.valor_retorno}
          onChange={formik.handleChange}
          error={
            formik.touched.payment &&
            Boolean(
              formik.errors.payment && formik.errors.payment.valor_retorno
            )
          }
          type="number"
          helperText={
            formik.touched.payment &&
            formik.errors.payment &&
            formik.errors.payment.valor_retorno
          }
        />
      </FormControl>
      <CardHeader title="Documentos" sx={{ mt: -1, ml: -1 }} />
      <Divider sx={{ mb: 2, mt: -1 }} />

      <FormControl>
        <TextField
          fullWidth
          select
          name="documents.tipo_de_deslocamento"
          label="Tipo de deslocamento"
          value={formik.values.documents.tipo_de_deslocamento}
          onChange={formik.handleChange}
          error={
            formik.touched.documents &&
            Boolean(
              formik.errors.documents &&
                formik.errors.documents.tipo_de_deslocamento
            )
          }
          helperText={
            formik.touched.documents &&
            formik.errors.documents &&
            formik.errors.documents.tipo_de_deslocamento
          }
        >
          <MenuItem value="Carro">Carro</MenuItem>
          <MenuItem value="Moto">Moto</MenuItem>
          <MenuItem value="Transporte público">Transporte público</MenuItem>
          <MenuItem value="Bicicleta">Bicicleta</MenuItem>
          <MenuItem value="À pé">À pé</MenuItem>
          <MenuItem value="Patinete">Patinete</MenuItem>
        </TextField>
      </FormControl>
      <FormControl
        sx={{
          flexDirection: "row",
          display: ["Carro", "Moto"].includes(
            formik.values.documents.tipo_de_deslocamento
          )
            ? "inline-flex"
            : "none",
        }}
      >
        {changeFiles.cnh_frente ? (
          <TextField
            fullWidth
            type="file"
            id="documents.cnh_frente"
            focused
            label="CNH - Frente"
            value={formik.values.documents.cnh_frente}
            onChange={(e) => {
              formik.handleChange(e);
              setFilesToUploads({
                ...filesToUpload,
                cnh_frente: e.target.files[0],
              });
            }}
            error={
              formik.touched.documents &&
              Boolean(
                formik.errors.documents && formik.errors.documents.cnh_frente
              )
            }
            helperText={
              formik.touched.documents &&
              formik.errors.documents &&
              formik.errors.documents.cnh_frente
            }
          />
        ) : (
          <TextField
            fullWidth
            label="CNH - Frente"
            focused
            value={user && user.tecnico ? user.tecnico.cnh_frente : ""}
            disabled
          />
        )}

        <Button
          sx={{ ml: 1 }}
          onClick={() =>
            setChangeFiles({
              ...changeFiles,
              cnh_frente: !changeFiles.cnh_frente,
            })
          }
        >
          <FileUpload />
        </Button>
        <Button
          sx={{ ml: 1 }}
          onClick={() =>
            window.open(
              `https://storage.googleapis.com/uteky/cnh/${
                user && user.tecnico && (user.tecnico.cnh_frente || "")
              }`,
              "_blank"
            )
          }
        >
          <Visibility />
        </Button>
      </FormControl>
      <FormControl
        sx={{
          flexDirection: "row",
          display: ["Carro", "Moto"].includes(
            formik.values.documents.tipo_de_deslocamento
          )
            ? "inline-flex"
            : "none",
        }}
      >
        {changeFiles.cnh_verso ? (
          <TextField
            fullWidth
            type="file"
            id="documents.cnh_verso"
            focused
            label="CNH - Verso"
            value={formik.values.documents.cnh_verso}
            onChange={(e) => {
              formik.handleChange(e);
              setFilesToUploads({
                ...filesToUpload,
                cnh_verso: e.target.files[0],
              });
            }}
            error={
              formik.touched.documents &&
              Boolean(
                formik.errors.documents && formik.errors.documents.cnh_verso
              )
            }
            helperText={
              formik.touched.documents &&
              formik.errors.documents &&
              formik.errors.documents.cnh_verso
            }
          />
        ) : (
          <TextField
            fullWidth
            label="CNH - Verso"
            focused
            value={user && user.tecnico ? user.tecnico.cnh_verso : ""}
            disabled
          />
        )}

        <Button
          sx={{ ml: 1 }}
          onClick={() =>
            setChangeFiles({
              ...changeFiles,
              cnh_verso: !changeFiles.cnh_verso,
            })
          }
        >
          <FileUpload />
        </Button>
        <Button
          sx={{ ml: 1 }}
          onClick={() =>
            window.open(
              `https://storage.googleapis.com/uteky/cnh/${
                user && user.tecnico && (user.tecnico.cnh_verso || "")
              }`,
              "_blank"
            )
          }
        >
          <Visibility />
        </Button>
      </FormControl>
      <FormControl
        sx={{
          display: ["Carro", "Moto"].includes(
            formik.values.documents.tipo_de_deslocamento
          )
            ? "inline-flex"
            : "none",
        }}
      >
        <TextField
          fullWidth
          type="date"
          id="documents.vencimento_cnh"
          focused
          label="Validade da CNH"
          value={formik.values.documents.vencimento_cnh}
          onChange={formik.handleChange}
          error={
            formik.touched.documents &&
            Boolean(
              formik.errors.documents && formik.errors.documents.vencimento_cnh
            )
          }
          helperText={
            formik.touched.documents &&
            formik.errors.documents &&
            formik.errors.documents.vencimento_cnh
          }
        />
      </FormControl>
      <FormControl
        sx={{
          flexDirection: "row",
          display: ["Carro", "Moto"].includes(
            formik.values.documents.tipo_de_deslocamento
          )
            ? "inline-flex"
            : "none",
        }}
      >
        {changeFiles.document ? (
          <TextField
            fullWidth
            type="file"
            id="documents.documento"
            focused
            label="Documento do Veículo"
            value={formik.values.documents.documento}
            onChange={(e) => {
              formik.handleChange(e);
              setFilesToUploads({
                ...filesToUpload,
                documento: e.target.files[0],
              });
            }}
            error={
              formik.touched.documents &&
              Boolean(
                formik.errors.documents && formik.errors.documents.documento
              )
            }
            helperText={
              formik.touched.documents &&
              formik.errors.documents &&
              formik.errors.documents.documento
            }
          />
        ) : (
          <TextField
            fullWidth
            label="Documento do Veículo"
            focused
            value={user && user.tecnico ? user.tecnico.documento : ""}
            disabled
          />
        )}

        <Button
          sx={{ ml: 1 }}
          onClick={() =>
            setChangeFiles({ ...changeFiles, document: !changeFiles.document })
          }
        >
          <FileUpload />
        </Button>
        <Button
          sx={{ ml: 1 }}
          onClick={() =>
            window.open(
              `https://storage.googleapis.com/uteky/documentos/${
                user && user.tecnico && (user.tecnico.documento || "")
              }`,
              "_blank"
            )
          }
        >
          <Visibility />
        </Button>
      </FormControl>
      <FormControl
        sx={{
          display: ["Carro", "Moto"].includes(
            formik.values.documents.tipo_de_deslocamento
          )
            ? "inline-flex"
            : "none",
        }}
      >
        <TextField
          fullWidth
          type="date"
          id="documents.vencimento_documento"
          focused
          label="Validade do documento"
          value={formik.values.documents.vencimento_documento}
          onChange={formik.handleChange}
          error={
            formik.touched.documents &&
            Boolean(
              formik.errors.documents &&
                formik.errors.documents.vencimento_documento
            )
          }
          helperText={
            formik.touched.documents &&
            formik.errors.documents &&
            formik.errors.documents.vencimento_documento
          }
        />
      </FormControl>
      <FormControl
        sx={{
          flexDirection: "row",
        }}
      >
        {changeFiles.antecedentes_criminais ? (
          <TextField
            fullWidth
            type="file"
            id="documents.antecedentes_criminais"
            focused
            label="Antecedentes criminais"
            value={formik.values.documents.antecedentes_criminais}
            onChange={(e) => {
              formik.handleChange(e);
              setFilesToUploads({
                ...filesToUpload,
                antecedentes_criminais: e.target.files[0],
              });
            }}
            error={
              formik.touched.documents &&
              Boolean(
                formik.errors.documents &&
                  formik.errors.documents.antecedentes_criminais
              )
            }
            helperText={
              formik.touched.documents &&
              formik.errors.documents &&
              formik.errors.documents.antecedentes_criminais
            }
          />
        ) : (
          <TextField
            fullWidth
            label="Antecedentes criminais"
            focused
            value={
              user && user.tecnico ? user.tecnico.antecedentes_criminais : ""
            }
            disabled
          />
        )}

        <Button
          sx={{ ml: 1 }}
          onClick={() =>
            setChangeFiles({
              ...changeFiles,
              antecedentes_criminais: !changeFiles.antecedentes_criminais,
            })
          }
        >
          <FileUpload />
        </Button>
        <Button
          sx={{ ml: 1 }}
          onClick={() =>
            window.open(
              `https://storage.googleapis.com/uteky/antecedentes-criminais/${
                user &&
                user.tecnico &&
                (user.tecnico.antecedentes_criminais || "")
              }`,
              "_blank"
            )
          }
        >
          <Visibility />
        </Button>
      </FormControl>
      <FormControl
        sx={{
          flexDirection: "row",
        }}
      >
        {changeFiles.curriculo ? (
          <TextField
            fullWidth
            type="file"
            id="documents.curriculo"
            focused
            label="Currículo"
            value={formik.values.documents.curriculo}
            onChange={(e) => {
              formik.handleChange(e);
              setFilesToUploads({
                ...filesToUpload,
                curriculo: e.target.files[0],
              });
            }}
            error={
              formik.touched.documents &&
              Boolean(
                formik.errors.documents && formik.errors.documents.curriculo
              )
            }
            helperText={
              formik.touched.documents &&
              formik.errors.documents &&
              formik.errors.documents.curriculo
            }
          />
        ) : (
          <TextField
            fullWidth
            label="Currículo"
            focused
            value={user && user.tecnico ? user.tecnico.curriculo : ""}
            disabled
          />
        )}

        <Button
          sx={{ ml: 1 }}
          onClick={() =>
            setChangeFiles({
              ...changeFiles,
              curriculo: !changeFiles.curriculo,
            })
          }
        >
          <FileUpload />
        </Button>
        <Button
          sx={{ ml: 1 }}
          onClick={() =>
            window.open(
              `https://storage.googleapis.com/uteky/curriculos/${
                user && user.tecnico && (user.tecnico.curriculo || "")
              }`,
              "_blank"
            )
          }
        >
          <Visibility />
        </Button>
      </FormControl>

      <Div sx={{ mx: 1 }}>
        <Button
          variant={"contained"}
          color="inherit"
          type="reset"
          sx={{ mr: 1 }}
          onClick={() => history.goBack()}
        >
          Cancelar
        </Button>

        {user && (user.status == "Pendente" || user.status == "Reprovado") && (
          <Button
            variant={"contained"}
            sx={{ mr: 1 }}
            color="info"
            onClick={() => {
              setStatus("Aprovado");
              setTimeout(() => {
                formik.submitForm();
              }, 500);
            }}
          >
            Aprovar
          </Button>
        )}

        {user && user.status == "Pendente" && (
          <Button
            variant={"contained"}
            color="warning"
            sx={{ mr: 1 }}
            onClick={() => setShowObservation(true)}
          >
            Reprovar
          </Button>
        )}

        {(!user || (user && user.status == "Ativo")) && (
          <Button variant={"contained"} type="submit" sx={{ mr: 1 }}>
            Salvar
          </Button>
        )}

        {user && user.status == "Desativado" && (
          <Button
            variant={"contained"}
            sx={{ mr: 1 }}
            color="info"
            onClick={() => {
              setStatus("Aprovado");
              setTimeout(() => {
                formik.submitForm();
              }, 500);
            }}
          >
            Ativar
          </Button>
        )}

        {user &&
          user.status != "Desativado" &&
          window.location.href.includes("/editar") && (
            <Button
              variant={"contained"}
              color="error"
              type="reset"
              onClick={desativaTecnico}
            >
              Desativar
            </Button>
          )}
      </Div>
    </form>
  );
};

export default FormTecnico;
